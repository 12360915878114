:root {
	--rosewater : #dc8a78;
	--flamingo : #dd7878;
	--pink : #ea76cb;
	--mauve : #8839ef;
	--red : #d20f39;
	--maroon : #e64553;
	--peach : #fe640b;
	--yellow : #df8e1d;
	--green : #40a02b;
	--teal : #179299;
	--sky : #04a5e5;
	--sapphire : #209fb5;
	--blue : #1e66f5;
	--lavender : #7287fd;
	--text : #4c4f69;
	--subtext1 : #5c5f77;
	--subtext0 : #6c6f85;
	--overlay2 : #7c7f93;
	--overlay1 : #8c8fa1;
	--overlay0 : #9ca0b0;
	--surface2 : #acb0be;
	--surface1 : #bcc0cc;
	--surface0 : #ccd0da;
	--base : #eff1f5;
	--mantle : #e6e9ef;
	--crust : #dce0e8;
}

code {
    background-color: var(--base);
    color: var(--text);
}

pre {
    background-color: var(--base);
    color: var(--text);
}

pre code span.comment {
    color: var(--overlay0);
    font-style: italic;
}  

pre code span.label {
    color: var(--sapphire);
}      

pre code span.constant {
    color: var(--peach);
}      

pre code span.function {
    color: var(--blue);
}      

pre code span.keyword {
    color: var(--mauve);
}      

pre code span.operator {
    color: var(--sky);
}      

pre code span.string {
    color: var(--green);
}      

pre code span.string-special {
    color: var(--pink);
}      

pre code span.tag {
    color: var(--pink);
}      

pre code span.type {
    color: var(--yellow);
}      

pre code span.boolean {
    color: var(--peach);
}  

pre code span.character {
    color: var(--teal);
}  

pre code span.character-special {
    color: var(--pink);
}  

pre code span.conditional {
    color: var(--mauve);
    font-style: italic;
}  

pre code span.debug {
    color: var(--pink);
}  

pre code span.define {
    color: var(--pink);
}  

pre code span.error {
    color: var(--red);
}  

pre code span.exception {
    color: var(--mauve);
}  

pre code span.float {
    color: var(--peach);
}  

pre code span.include {
    color: var(--mauve);
}  

pre code span.macro {
    color: var(--mauve);
}

pre code span.method {
    color: var(--blue);
}  

pre code span.number {
    color: var(--peach);
}  

pre code span.preproc {
    color: var(--pink);
}  

pre code span.repeat {
    color: var(--mauve);
}  

pre code span.storageclass {
    color: var(--yellow);
}  

pre code span.todo {
    background-color: var(--yellow);
    color: var(--base);
}  

pre code span.structure {
    color: var(--yellow);
}

pre code span.typedef {
    color: var(--yellow);
}

pre code span.array {
    color: var(--lavender);
}
