* {
    font-family: "Noto Sans SC";
}

#root {
    min-height: 100vh;
}

#shabi::before {
    content: "关注永雏塔菲喵，关注永雏塔菲谢谢喵";
}

#shabi:hover::before {
    content: "关注猫雷喵，关注猫雷谢谢喵";
}

#avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 20px auto;
    display: block;
}

.background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-position-x: center;
    background-position-y: -200px;
    background-repeat: no-repeat;
}

.container {
    width: 70em;
    min-height: 100vh;
    margin: 0 auto;
    background: #ffffffe8;
}

@media screen and (max-width: 70em) {
    .container {
        width: 100%;
    }
}

.header {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 15px;
    font-family: "Noto Sans SC";
    text-align: center;
    color: #505050;
}

.nav {
    margin-top: 20px;
    font-size: 20px;
}

.nav a {
    text-decoration-line: none;
    color: #606060;
}

.divider {
    margin-right: 30px;
    margin-left: 30px;

    height: 2px;

    border: none;

    background-color: #d0d0d0;
}

.vertical-divider {
    margin: 15px auto;

    width: 2px;
    height: 150px;

    border-left: 1px solid hsla(200, 10%, 50%,100);

    border: none;

    background-color: #d0d0d0;
}

@media screen and (max-width: 70em) {
    .vertical-divider {
        display: none;
    }
}

.category-list {
    margin: 0 30px;
}

.category-list-title {
    color: #606060;
    text-align: center;
    margin: 20px 0;
}

.category-list ul {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 30px;
    list-style: none;
}

.category-list ul li {
    margin-top: 10px;
    margin-bottom: 10px;
}

.category-list ul li a {
    text-decoration-line: none;
    font-size: 20px;
    color: #606060;
}

.post-list {
    margin: 0 30px;
}

.post-title {
    margin-top: 30px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.post-title a {
    text-decoration-line: none;
    color: #606060;
}

.post-info {
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
    color: #606060;
}

.post-info a {
    text-decoration-line: none;
    color: #606060;
}

.paginator {
    text-align: center;
    font-size: 20px;
    margin: 20px 0;
}

.paginator-item {
    height: 40px;
    width: 40px;
    text-decoration-line: none;
    display: inline-block;
    margin: 0 10px;
}

.paginator-item a {
    text-decoration-line: none;
    vertical-align: middle;
    font-weight:500;
    color: #606060;
}

.paginator-item-select {
    border-radius: 20px;
    background-color: #e8e8e8;
}

.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 15px;
    text-align: center;
    color: #606060;
}

.footer p {
    margin: 10px 10px;
}

.footer p a {
    text-decoration-line: none;
    color: #606060;
}

.content {
    margin: 30px;
    font-size: 18px;
    color: #606060;
}

.content * {
    margin-top: 20px;
    margin-bottom: 20px;
}

.content h1 {
    font-size: 25px;
    font-weight: bold;
    border-bottom: 2px solid #e8e8e8;
}

.content h1:hover::before {
    content: "#";
    color: #606060;
    margin-right: 10px;
}

.content h2 {
    font-size: 25px;
    font-weight: bold;
}

.content h2:hover::before {
    content: "##";
    color: #606060;
    margin-right: 10px;
}

.content h3 {
    font-size: 23px;
    font-weight: bold;
}

.content h3:hover::before {
    content: "###";
    color: #606060;
    margin-right: 10px;
}

.content h4 {
    font-size: 21px;
    font-weight: bold;
}

.content h4:hover::before {
    content: "####";
    color: #606060;
    margin-right: 10px;
}

.content h5 {
    font-size: 19px;
    font-weight: bold;
}

.content h5:hover::before {
    content: "#####";
    color: #606060;
    margin-right: 10px;
}

.content h6 {
    font-size: 17px;
    font-weight: bold;
}

.content h6:hover::before {
    content: "######";
    color: #606060;
    margin-right: 10px;
}

.content img {
    display: block;
    margin: 20px auto;
    max-width: 100%;
    height: auto;
}

.content hr {
    border: none;
    height: 2px;
    background-color: #e8e8e8;
}

.content p {
    line-height: 150%;
    word-wrap: break-word;
}

.content p code {
    border-radius: 7px;
    padding: 2px 10px;
}

.content table {
    border-collapse: collapse;
    border-color: #b0b0b0;
    overflow-x: auto;
    display: block;
}

.content table th {
    border: 2px solid #606060;
    padding: 5px;
    border-color: #b0b0b0;
    white-space: pre-wrap;
}

.content table td {
    border: 2px solid #606060;
    padding: 5px;
    border-color: #b0b0b0;
    white-space: pre-wrap;
}

.content pre {
    padding: 10px;
    border-radius: 10px;

    display: block;
    overflow-x: auto;
}

.content pre:hover::before {
    content: "```" attr(language) "\a";
    color: #606060;
    margin-right: 10px;
}

.content pre:hover::after {
    content: "\a```";
    color: #606060;
    margin-left: 10px;
}

.content pre code {
    left: 10px;
    font-family: 'Fira Code';
}

.content pre code {
    counter-reset: line;
    counter-increment: line 0;
}

.content pre code .line {
    font-weight: 500;
    font-family: 'Fira Code';
}

.content pre code .line *{
    font-weight: 500;
    font-family: 'Fira Code';
}

.content pre code .line::before {
    content: counter(line);
    counter-increment: line;
    display: inline-block;
    width: 3ex;
    margin: 0 1ex;
    color: #d0d0d0;
}

.content blockquote {
    border-left: 7px solid #e8e8e8;
    background-color: #f8f8f8;
    padding-left: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #606060;
}

.content blockquote p {
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
}

.content blockquote blockquote {
    margin-bottom: 0;
    padding-bottom: 0;
}

.content ul {
    margin-left: 25px;
}

.content ul li::marker {
    color: #606060;
}

.content ol {
    margin-left: 22px;
}

.content ol li::marker {
    color: #606060;
    font-weight: bold;
}

.content .container-danger {
    border-radius: 10px;
    background-color: #f04040;
    color: #ffffff;
    padding: 10px;
}

.content .container-warning {
    border-radius: 10px;
    background-color: #f0a840;
    color: #ffffff;
    padding: 10px;
}

.content .container-info {
    border-radius: 10px;
    background-color: #40a8f0;
    color: #ffffff;
    padding: 10px;
}

.MarkdownEditor {
    position: relative;
    width: 100%;
    margin-top: 20px;
    height: 900px;
    display: flex;
    border: 1px solid #e8e8e8;
}

.MarkdownEditor .textarea {
    position: absolute;
    height: 100%;
    width: 50%;
}

.MarkdownEditor .preview {
    position: absolute;
    background-color: #ffffff;
    height: 100%;
    width: 50%;
    left: 50%;
    border-left: 1px solid #e8e8e8;
    overflow-y: auto;
}

.MarkdownEditor .content {
    margin: 0;
    padding: 10px;
}

.MarkdownEditor textarea {
    padding: 10px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    font-size: 18px;
    font-family: 'Fira Code';
    color: #606060;
}

.button-group {
    margin: 30px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
}

.button-group button {
    margin-right: 10px;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Fira Code';
    color: #606060;
    background-color: #f0f0f0;
    cursor: pointer;
}

.button-group button:hover {
    background-color: #d0d0d0;
}

.button-group label {
    margin-right: 10px;
    padding: 10px;
    font-size: 18px;
    font-family: 'Fira Code';
    color: #606060;
}

.button-group select {
    margin-right: 10px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Fira Code';
    color: #606060;
    cursor: pointer;
}

.button-group select option {
    padding: 10px;
    font-size: 18px;
    font-family: 'Fira Code';
    color: #606060;
    background-color: #f0f0f0;
}

.friend-list {
    margin: 40px 30px;
}

.friend-card {
    margin: 15px 30px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.friend-avatar {
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 10px;
    position: relative;
}

.friend-info {
    position: relative;
    font-family: 'Noto Sans SC';
    color: #606060;
    margin: 10px 40px;
    display: flex;
    flex-direction: column;

}
